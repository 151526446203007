// import { features } from "@sciam/chargebee/constants.json";
import { ChargebeePlanConfig, plans } from "@sciam/chargebee/plans.json";
import { createContext, useContext } from "react";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";

interface PlanContext {
  plan?: ChargebeePlanConfig | null;
  couponCode?: string;
  customFields?: Record<string, any>;
  eligibility?: PlanPurchaseEligibility;
}
const PlanContext = createContext<PlanContext>({});

export function usePlan() {
  return useContext(PlanContext);
}

interface PlanProviderProps extends PlanContext {
  planId: string;
  children: React.ReactNode;
}
export function PlanProvider({ planId, couponCode, customFields, children }: PlanProviderProps) {
  const plan = plans.find((plan) => plan.id === planId);

  // Get the user's current plan (TODO: Hoist this up to a global context e.g. useUser)
  const currentPlan = useUserCurrentPlan();
  // Check whether the user is eligible to checkout this plan
  const eligibility = getPurchaseEligibility(plan, currentPlan);

  return (
    <PlanContext.Provider
      value={{
        plan,
        couponCode,
        customFields,
        eligibility,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

interface PlanPurchaseEligibility {
  /** User is eligible to purchase this plan */
  isPurchasable: boolean;
  /** The product is enabled for checkout from Chargebee's dashboard */
  enabledForCheckout: boolean;
  /** User already has a plan with the same exact price_id */
  userAlreadyHasPlan: boolean;
  /**
   * User already has a plan with the same legacy_resource_id (equivalent Piano term)
   * or equivalent feature set
   */
  userHasSimilarPlan: boolean;
  /**
   * Whether the plan is an objective downgrade from the user's current plan,
   * i.e. the user will not gain any features
   */
  isDowngrade?: boolean;
  /**
   * Whether the plan is an objective upgrade from the user's current plan
   * i.e. the user will not lose any current features
   */
  isUpgrade?: boolean;
}
export const getPurchaseEligibility = (
  plan: ChargebeePlanConfig | null,
  currentPlan: ChargebeePlanConfig | null,
): PlanPurchaseEligibility => {
  const enabledForCheckout = !!plan && plan.enabled_for_checkout;
  const canEvaluatePlans = !!plan && !!currentPlan && !plan.is_giftable;
  const userAlreadyHasPlan = canEvaluatePlans && currentPlan.id === plan.id;

  // Check if the plan is an upgrade or downgrade
  const isUpgrade =
    canEvaluatePlans &&
    !userAlreadyHasPlan &&
    plan?.features?.length > currentPlan?.features?.length &&
    currentPlan?.features?.every((feature) => plan?.features?.includes(feature));

  const isDowngrade =
    canEvaluatePlans &&
    !userAlreadyHasPlan &&
    plan?.features?.length < currentPlan?.features?.length &&
    plan?.features?.every((feature) => currentPlan?.features?.includes(feature));

  const userHasSimilarPlan =
    canEvaluatePlans &&
    // Check if the plans match by legacy_resource_id (Piano term)
    (currentPlan?.legacy_resource_id === plan.legacy_resource_id ||
      // Check if the plans match by feature set
      (!isUpgrade && !isDowngrade));

  return {
    isPurchasable: canEvaluatePlans
      ? enabledForCheckout && !userAlreadyHasPlan && !userHasSimilarPlan
      : enabledForCheckout,

    // These can be used to display reasons why a plan is not purchasable
    enabledForCheckout,
    userAlreadyHasPlan,
    userHasSimilarPlan,

    // These can be used to highlight the plan as an upgrade or downgrade
    isUpgrade,
    isDowngrade,
  };
};

/**
 * Chargebee doesn't directly attach a user to the plan they purchased,
 * so we need to match them up based on their subscription's price_id
 * @param {Object} options
 * @param {boolean} options.strict If true, only match the primary Customer, i.e. the Customer a checkout modal will use. Default: true
 * @param {boolean} options.includeCancelled If true, allow matching cancelled subscriptions. Default: false
 */
export function useUserCurrentPlan({ strict = true, includeCancelled = false } = {}) {
  const { primaryActiveSubscription, primarySubscriptions, activeSubscriptions } =
    useUserSubscriptions();

  const userSubscription =
    primaryActiveSubscription ||
    (strict && activeSubscriptions[0]) ||
    (includeCancelled && primarySubscriptions[0]) ||
    null;

  // Temporary debug log
  // @TODO: Remove this
  if (typeof window !== "undefined") {
    console.log("[useUserCurrentPlan]", {
      userSubscription,
      primaryActiveSubscription,
      primarySubscriptions,
      activeSubscriptions,
    });
  }

  if (!userSubscription) return null;

  return plans.find((plan) => plan.default_price_id === userSubscription.price_id) || null;
}

/**
 * Get the user's subscriptions from the SciAm JWT
 * @TODO: Hoist this up to a global context e.g. useUser
 */
export function useUserSubscriptions() {
  const { payload } = useSciAmJwtSync();
  const primaryCustomer = payload?.sub;
  const subscriptions = payload?.subscriptions || [];

  return {
    primaryActiveSubscription: subscriptions.find(
      (sub) => sub.customer_id === primaryCustomer && sub.status === "active",
    ),
    activeSubscriptions: subscriptions.filter((sub: any) => sub.status === "active"),
    primarySubscriptions: subscriptions.filter((sub: any) => sub.customer_id === primaryCustomer),
    allSubscriptions: subscriptions,
  };
}
